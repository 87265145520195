import { Controller, useFormContext } from 'react-hook-form';

import { type ModelsMessageCondition } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { type MessageVars } from './MessageCampaign';
import { type MessageLogicFormData } from './MessageLogicEditor';

function ExpressionHint(props: { className?: string }) {
  return (
    <div
      className={` ${props.className} text-icon-gray text-3xs font-medium text-left`}
    >
      <strong>Operations: </strong>
      {`+ - * / > >= < <= == != && || !`}
    </div>
  );
}

function MessageConditionVarsHint(props: {
  vars: MessageVars['condition'];
  onClick: (key: string) => void;
  className?: string;
}): JSX.Element {
  return (
    <div
      className={`${props.className} text-icon-gray text-3xs font-medium flex items-center gap-[2px] flex-wrap`}
    >
      <strong>Variables: </strong>
      {Object.entries(props.vars).map(([key]) => (
        <button
          key={key}
          type='button'
          onClick={() => props.onClick(key)}
          className='btn'
        >
          {key}
        </button>
      ))}
    </div>
  );
}

export function MessageConditionEditor(props: {
  vars: MessageVars['condition'];
}) {
  const { getFieldState } = useFormContext<MessageLogicFormData>();

  const validateCondition = async (condition: ModelsMessageCondition) => {
    if (!getFieldState('condition').isDirty) return true;
    if (!condition.expression) return true;

    try {
      await apiService.message.validateCondition({
        condition,
        vars: props.vars,
      });
    } catch (e) {
      const msg = err2s(e);
      if (msg) return msg;
    }

    return true;
  };

  return (
    <Controller<MessageLogicFormData, 'condition'>
      name='condition'
      render={({ field: { value: condition, onChange }, formState }) => (
        <div>
          <div className='mb-2 font-bold'>Condition</div>

          <input
            value={condition.expression}
            onChange={(e) =>
              onChange({
                expression: e.target.value,
              })
            }
            className={`${
              formState.errors.condition ? 'field-error' : 'field'
            } w-1/2 mb-0`}
            maxLength={200}
          ></input>

          {formState.errors.condition && (
            <div className='text-error text-3xs mt-1 ml-1 text-red-002'>
              {formState.errors.condition.message}
            </div>
          )}

          <ExpressionHint className='mt-1' />

          <MessageConditionVarsHint
            className='mt-1'
            vars={props.vars}
            onClick={(key) => {
              onChange({
                expression: condition.expression + key,
              });
            }}
          />
        </div>
      )}
      rules={{
        validate: validateCondition,
      }}
    />
  );
}
